import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import '../styles/style.scss';

const AvisoLegal = () => {
    return(
        <Layout>
            <SEO 
            title="Aviso legal"
            meta={{
                robots: 'noindex, nofollow'
            }}
            >
            </SEO>

            <section className="section">
                <div className="container">
                    <h1 className="title">Aviso Legal</h1>
                    <p>
                        La web <a href="https://raraavislab.es">raraavislab.es</a>, para dar cumplimiento con lo establecido en la Ley 34/2002, de 11 de julio, de servicios
                         de la sociedad de la información y de comercio electrónico, a continuación se indican los datos de información general de este Sitio Web.
                    </p>
                    
                    <div className="columns is-mobile is-centered">
                        <div className="column is-12-touch is-7-desktop">
                            <div className="box has-margin-top-25">
                                <div className="content">
                                    <ul>
                                        <li>Titular RARA AVIS LAB S.L.</li>
                                        <li>C.I.F.: B-99513525</li>
                                        <li>Nombre comercial registrado compañía: Rara Avis Lab SL</li>
                                        <li>Nombre marca registrada y propiedad de RARA AVIS LAB S.L.: Rara Avis Lab</li>
                                        <li>Oficinas: Calle Pablo Picasso 11, local – 50019 ZARAGOZA</li>
                                        <li>Email: info@raraavislab.es</li>
                                        <li>Teléfono: +34 636056166</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>
                    RARA AVIS LAB, S.L. Registro Mercantil de Zaragoza Tomo 4309, Folio 116, Sección 8 Inscripción 1 Hoja Z-63258. ofrece en el Sitio
                     Web raraavislab.es, información relacionada con el tipo de actividad, productos y servicios que realiza. El acceso al Sitio Web de 
                     RARA AVIS LAB S.L., raraavilab.es y a la información relativa a cualesquiera de los productos y servicios contenidos en el mismo, comporta 
                     la aceptación de las condiciones previstas en el presente Aviso Legal. Por ello le recomendamos que lea atentamente su contenido si 
                     usted desea acceder y hacer uso de la información y los servicios ofrecidos desde el Sitio Web. RARA AVIS LAB S.L. ofrece el acceso al 
                     sitio Web raraavilab.es en las condiciones de uso que se describen a continuación.
                    </p>
                </div>
            </section>

            <section className="section" style={{'paddingTop': 0}}>
                <div className="container">
                    <h1 className="title">Términos y condiciones</h1>

                    <h2 className="title is-3 is-marginless">Términos de uso</h2>
                    <p>
                        Al usar nuestra página web, aceptas los términos de uso. Es posible que cambiemos o actualicemos dichos términos, así que por favor,
                         revisa regularmente esta página. No afirmamos ni garantizamos, que la información en nuestra página web sea precisa, completa o actual.
                          Esto incluye información de precios y disponibilidad. Reservamos el derecho a corregir cualquier error u omisión, y a cambiar o
                           actualizar información en cualquier momento sin previo aviso. Reservamos el derecho a cancelar un pedido o eliminar a cualquier
                            usuario del cual sospechemos.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-20">Copyright</h2>
                    <p>
                    Todo el contenido de nuestra web, incluyendo textos, gráficos, iconos, imágenes, y software es propiedad de Rara Avis Lab SL, y está
                     protegido por las leyes Españolas e internacionales. Usted puede copiar electrónicamente e imprimir, copias de páginas de este sitio
                      web, únicamente con fines no comerciales y/o personales. Cualquier otro uso de nuestra página web, incluyendo su reproducción, esta
                       terminantemente prohibido sin nuestro permiso escrito previo.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-20">Procesamiento seguro</h2>
                    <p>
                        <a href="https://raraaavislab.es">La web</a> utiliza un tipo de procesamiento seguro, llamado Tecnología de Ecnriptación SSL, el cual
                         es el estándar de la industria. SSL (Seguridad de Capa de Transporte) es un protocole desarrollado para la transmisión de
                          información privada en internet. SSL utiliza una clave privada para encriptar tus datos.
                    </p>

                    <h2 className="title is-3 is-marginless has-margin-top-20">Privacidad</h2>
                    <p>
                        <a href="https://raraaavislab.es">La web</a> respeta la información de sus clientes como privada y confidencial y nunca se compartirá
                         con agentes externos. Tu información esta asegurada y encriptada con el software SSL.
                    </p>
                </div>
            </section>
        </Layout>
    )
}

export default AvisoLegal;